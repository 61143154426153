<template>
  <div class="d-flex justify-content-end mb-1">
    <!--begin::Wrapper-->
    <div class="d-flex flex-column align-items-end">
      <!--begin::User-->
      <div class="d-flex align-items-center mb-2  mt-5" v-if="header">
        <!--begin::Details-->
        <div class="me-3">
          <span class="text-muted fs-7 mb-1">{{ formatDateAgo(time) }}</span>
        </div>
        <!--end::Details-->

        <div class="symbol symbol-35px symbol-circle">
          <el-avatar size="small">{{image}}</el-avatar>
        </div>
      </div>
      <!--end::User-->

      <!--begin::Text-->
      <div
        class="
          p-5
          rounded
          text-dark
          fw-bold
          mw-lg-400px
          text-end
          mb-1
        "
        :class="{'fs-1' : lengthChar(text) <=2 }"
        style="background-color: #fff !important; border-radius: 17px 0px 17px 7px !important; margin-right : 10px; box-shadow: -2px 3px 6px 0px #f3f3f3;"
        data-kt-element="message-text"
        v-html="text"
        v-if="text"
      >
      </div>
      <div v-if='files' class="p-5 rounded text-dark fw-bold mw-lg-400px text-end mb-1" style="background-color: #fff !important; border-radius: 17px 0px 17px 7px !important; margin-right : 10px; box-shadow: -2px 3px 6px 0px #f3f3f3;">
        <template v-for="(item, index) in files" :key="index">
            <a class="btn btn-sm btn-custom-white px-4 py-2 me-4 mb-2 mt-2" style="border: none !important;width: 100%; text-align: left; text-end">
              <i class="las la-cloud-download-alt text-info fs-2 me-2"></i>   
              <span class="fs-7 text-gray-600">Partage du fichier</span>
              <div style="margin-left: 31px;">{{ item.name }}</div>
              <span style="margin-left: 31px;" class="fs-7 text-gray-400"><i>Temporaire</i></span>
            </a>
        </template>
      </div>
      <!--end::Text-->
    </div>
    <!--end::Wrapper-->
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import * as moment from "moment";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    header: String,
    files: Object as any,
  },
  setup() {
    //moment.default.locale("fr");

    

    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD hh:mm:ss").fromNow();
    };

    const lengthChar = (str) => {
      return str.length;
    };

    return {
      formatDateAgo,
      lengthChar,
    };
  },
});


</script>
